import {
    REMOVE_COUPON_REQUEST,
    REMOVE_COUPON_SUCCESS,
    REMOVE_COUPON_FAIL,
  } from "../../../shared/constants";
  
  const initialState = {
    loading: false,
    message: "",
    error: null,
  };
  
  const removeCouponReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case REMOVE_COUPON_REQUEST:
        return {
          ...state,
          loading: true,
          message: "",
          error: null,
        };
      case REMOVE_COUPON_SUCCESS:
        return {
          ...state,
          loading: false,
          message: payload.message,
          error: null,
        };
      case REMOVE_COUPON_FAIL:
        return {
          ...state,
          loading: false,
          message: "",
          error: payload.error,
        };
      default:
        return state;
    }
  };
  export default removeCouponReducer;