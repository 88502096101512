import {
    CHANGE_LANGUAGE_REQUEST,
    CHANGE_LANGUAGE_SUCCESS,
    CHANGE_LANGUAGE_FAIL,
  } from "../../shared/constants";
  
  const initialState = {
    loading: false,
    message: null,
  };
  
  const changeLanguageReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case CHANGE_LANGUAGE_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
        };
      case CHANGE_LANGUAGE_SUCCESS:
        return {
          ...state,
          loading: false,
          message: null,
        };
      case CHANGE_LANGUAGE_FAIL:
        return {
          ...state,
          loading: false,
          message: payload.message,
        };
      default:
        return state;
    }
  };
  export default changeLanguageReducer;
  