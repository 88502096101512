import {
  FEATURES_REQUEST,
  FEATURES_SUCCESS,
//   FEATURES_FAIL,
} from "../../shared/constants";
// import { api } from "../../shared/shared";

const fetchFeaturesBegin = () => ({
  type: FEATURES_REQUEST,
});

const fetchFeaturesSuccess = (features) => ({
  type: FEATURES_SUCCESS,
  payload: { features },
});

// const fetchFeaturesFailure = (errors) => ({
//   type: FEATURES_FAIL,
//   payload: { errors },
// });

const fetchFeatures = () => (dispatch) => {
  dispatch(fetchFeaturesBegin());
  dispatch(fetchFeaturesSuccess(window.storeInfo.features));
};
export default fetchFeatures;
