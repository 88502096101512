import axios from "axios";
import {isEqual} from "lodash";

export const baseURL = '';
// export const baseURL = "http://localhost:8000/";

export const api = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const getPercentageWidth = (val, max = 5) => val * 100 / max;

export const number_format = (number) => new Intl.NumberFormat('fr').format(number);

export const compareObjects = (obj1 = {}, obj2 = {}) => {
  obj1 = removeNullValues({ ...obj1 });
  if(Object.keys(obj1).length)
  {
    let tempObj1 = {}
    for (let key in obj1)
      tempObj1[key] = obj1[key].toLowerCase();

    let tempObj2 = {}
    for (let key in obj2)
      tempObj2[key] = obj2[key].toLowerCase();

    // If the selected properties in frontend and each product option properties are the same
    // Which means each object from the selected properties should be exactly the same as option
    return isEqual(tempObj1, tempObj2) ? true : false
  }

  return false;
};

export const removeNullValues = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === "")
      delete obj[key]
  })
  return obj
}

export const serialize = function (obj, prefix) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        serialize(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}


export const scrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
};