import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { Suspense, lazy, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import fetchCsrfToken from "./redux/actions/csrfTokenAction";
import fetchFeatures from "./redux/actions/featuresAction";
import { fetchUserAction } from "./redux/actions/auth/userActions";
import fetchStoreInfos from "./redux/actions/settings/storeInfosActions";
import { fetchGetCart } from "./redux/actions/cart/getCartActions";

const NotFound = lazy(() => import("./pages/NotFound"));
const Cart = lazy(() => import("./pages/Cart"));
const AppLayout = lazy(() => import("./layout/AppLayout"));
const Authentication = lazy(() => import("./pages/Authentication"));
const Home = lazy(() => import("./pages/Home"));
const Checkout = lazy(() => import("./pages/Checkout"));
const Shop = lazy(() => import("./pages/Shop"));
const Product = lazy(() => import("./pages/Product"));
const Wishlist = lazy(() => import("./pages/Wishlist"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const TermsPolicies = lazy(() => import("./pages/TermsPolicies"));

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const authState = useSelector((state) => state.auth.authenticated);
  const featuresState = useSelector((state) => state.features);

  useEffect(() => {
    dispatch(fetchCsrfToken());
    dispatch(fetchUserAction());
    dispatch(fetchStoreInfos());
    dispatch(fetchFeatures());
  }, []);

  useEffect(() => {
    if (featuresState?.features?.includes("add-to-cart"))
      dispatch(fetchGetCart());
  }, [featuresState?.features?.includes("add-to-cart")]);

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<Home />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="shop/:productSlug" element={<Product />} />
            <Route path="/categories/:categorySlug" element={<Shop />} />

            <Route element={<RequireAuth authState={authState} />}>
              {featuresState?.features?.includes("wishlist") && (
                <Route path="/wishlist" element={<Wishlist />} />
              )}
            </Route>

            <Route element={<RequireGuest authState={authState} />}>
              <Route path="/:option" element={<Authentication />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Route>

            <Route path="/checkout" element={<Checkout />} />
            <Route path="/shopping-cart" element={<Cart />} />
            <Route path="/terms-and-policies" element={<TermsPolicies />} />

            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

const RequireAuth = ({ authState }) => {
  const location = useLocation();
  return authState.isLogged ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

const RequireGuest = ({ authState }) => {
  const location = useLocation();
  return authState.user ? (
    <Navigate to="/" state={{ from: location }} />
  ) : (
    <Outlet />
  );
};

export default App;
