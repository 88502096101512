import { combineReducers } from "redux";
import orders from "./ordersReducer";
import order from "./orderReducer";
import payment from "./paymentReducer";

const rootOrdersReducer = combineReducers({
  orders,
  order,
  payment
});
export default rootOrdersReducer;
