import {
  STORE_INFOS_REQUEST,
  STORE_INFOS_SUCCESS,
  // STORE_INFOS_FAIL,
} from "../../../shared/constants";

const fetchStoreInfosBegin = () => ({
  type: STORE_INFOS_REQUEST,
});

const fetchStoreInfosSuccess = (store_infos) => ({
  type: STORE_INFOS_SUCCESS,
  payload: { store_infos },
});

// const fetchStoreInfosFailure = (error) => ({
//   type: STORE_INFOS_FAIL,
//   payload: { error },
// });

const fetchStoreInfosAction = () => (dispatch) => {
  dispatch(fetchStoreInfosBegin());
  // api
  //   .get("/api/store-infos")
  //   .then((res) => {
  //     if (res.data.success) {
  //       dispatch(fetchStoreInfosSuccess(res.data.data));
  //     } else {
  //       dispatch(fetchStoreInfosFailure(res.data.message));
  //     }
  //   })
  //   .catch((error) => {
  //     dispatch(fetchStoreInfosFailure(error.message));
  //   });
  dispatch(fetchStoreInfosSuccess(window.storeInfo));
};
export default fetchStoreInfosAction;
